<template>
  <b-upload
    v-model="file"
    @input="uploadImage"
    expanded
    accept="image/*"
    class="file-label"
  >
    <slot />
  </b-upload>
</template>

<script>
import { apiPostUpload } from "@/api/requests";

export default {
  name: "AppControlImage",
  props: {
    // `v-model`.
    value: Object,
    apiUpload: {
      type: Function,
      default: apiPostUpload,
    },
  },
  data: function () {
    return {
      file: null,
    };
  },
  methods: {
    uploadImage(file) {
      this.apiUpload(file).then(attach => this.$emit("input", attach));
    },
  },
};
</script>

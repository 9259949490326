






import { Component, Vue } from "vue-property-decorator";

import ProfileEdit from "@/components/ProfileEdit.vue";
import { Api } from "@/constants";
import { usersStore } from "@/store";

interface Event {
  payload: EventPayload;
}

interface EventPayload {
  fullName: string;
  avatarId: string;
}

@Component({ components: { ProfileEdit } })
export default class ProfileEditContainer extends Vue {
  get user() {
    return usersStore.user;
  }
  get errors() {
    return usersStore.getErrors(Api.UsersUpdateUser);
  }
  async sendProfile($event: Event) {
    await usersStore.updateUser({
      fullName: $event.payload.fullName,
      avatarId: $event.payload.avatarId,
    });

    if (usersStore.isSuccess(Api.UsersUpdateUser)) {
      this.$buefy.snackbar.open({
        type: "is-success",
        message: this.$t("profile.form.success").toString(),
      });
    }
  }
}

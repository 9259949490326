<template>
  <profile-edit-container />
</template>

<script>
import ProfileEditContainer from "@/containers/ProfileEditContainer";
import { usersStore } from "@/store";

const loadProfileMe = (to, from, next) => {
  usersStore.loadUser().then(() => next());
};

export default {
  name: "ProfileEditView",
  metaInfo() {
    return {
      title: this.$t("viewsMeta.profileEdit.title"),
    };
  },
  components: { ProfileEditContainer },
  beforeRouteEnter: loadProfileMe,
  beforeRouteUpdate: loadProfileMe,
};
</script>

<template>
  <div>
    <section class="section">
      <div class="container">
        <div>
          <h1 class="title">{{ $t("profile.form.title") }}</h1>
          <app-form ref="form" :errors="errors" @submit="submit">
            <div
              class="
                mb-3
                is-flex
                is-justify-content-center
                is-align-items-center
                is-flex-direction-column
              "
            >
              <app-form-field name="avatar" label="">
                <app-control-image v-model="avatar" :api-upload="apiUploadImage">
                  <app-avatar :avatar="avatar" :size="96" />
                  <div class="is-overlay" style="top: 85%; left: 85%">
                    <font-awesome-layers>
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        style="color: black"
                        transform="grow-10"
                      />
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        style="color: white"
                        transform="grow-8"
                      />
                      <font-awesome-icon
                        :icon="['fas', 'pencil-alt']"
                        style="color: black"
                      />
                    </font-awesome-layers>
                  </div>
                </app-control-image>
              </app-form-field>
            </div>
            <app-form-field
              name="fullName"
              label-position="inside"
              :label="$t('profile.form.name')"
              :rules="{ required: true, max: 30 }"
              :message="$t('profile.form.nameMessage')"
            >
              <b-input lazy v-model="fullName" :status-icon="false" />
            </app-form-field>
            <app-form-errors />
            <b-button native-type="submit" type="is-white mt-3">
              {{ $t("root.save") }}
            </b-button>
          </app-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { extend } from "vee-validate";
import { max, regex, required } from "vee-validate/dist/rules";

import { apiPostUserAvatar } from "@/api/requests";
import AppAvatar from "@/components/AppAvatar";
import AppControlImage from "@/components/AppControlImage";
import AppForm from "@/components/AppForm";
import AppFormErrors from "@/components/AppFormErrors";
import AppFormField from "@/components/AppFormField";

extend("max", max);
extend("regex", regex);
extend("required", required);

export default {
  name: "ProfileEdit",
  components: {
    AppAvatar,
    AppControlImage,
    AppForm,
    AppFormErrors,
    AppFormField,
    FontAwesomeLayers,
  },
  props: {
    user: Object,
    errors: Object,
  },
  data() {
    return {
      fullName: this.user.fullName,
      avatar: this.user.avatar,
      apiUploadImage: apiPostUserAvatar,
    };
  },
  methods: {
    submit() {
      this.$emit("submitted", {
        payload: {
          fullName: this.fullName,
          avatarId: this.avatar && this.avatar.id,
        },
      });
    },
  },
};
</script>
